<template>
    <v-row no-gutters class="py-5">
        <v-col cols="12" class="d-flex flex-row justify-center align-center">
            <LoadingIndicator></LoadingIndicator><span class="ml-3 text--grey">{{ $t('message.loadingRequestedView') }}</span>
        </v-col>
    </v-row>
</template>

<script>
import LoadingIndicator from "Components/Appic/LoadingIndicator";
export default {
    name: "LoadingComponent",
    components: {LoadingIndicator}
}
</script>

<style scoped>

</style>