<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="SalesOrdersOverview"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <SalesOrdersListingNew/>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";

    const ExportTable = () => import("../../../components/Appic/ExportTable");
    const SalesOrdersListingNew = () => ({
        component: import("Components/Appic/SalesOrdersListingNew"),
        loading: LoadingComponent
    });

    export default {
        name: "SalesOrdersOverview",
        components: {SalesOrdersListingNew, ExportTable},
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>